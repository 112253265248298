import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/About';

const PersonalBlogPage = () => {
  return (
    <Layout>
      <SEO title="About Me" description="A page all about Jenny-Lee Fyfe, the founder of Sweet Dreams Jellybean, Certified sleep consultant." />

      <About />
    </Layout>
  );
};

export default PersonalBlogPage;

// EXAMPLE-RAF page query
// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//         description
//       }
//     }
//   }
// `;
