import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MainLogoAvatar from '../../MainLogoAvatar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.light,
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  infoCardContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  infoCardHeader: {
    backgroundColor: theme.palette.primary.light,
  },
  icon: {
    fontSize: '3rem',
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
}));

const AboutDetails = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container justify="center" spacing={3}>
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h4">Jenny-Lee</Typography>}
              avatar={<MainLogoAvatar />}
            ></CardHeader>
            <CardContent className={classes.infoCardContent}>
              <Typography variant="body1">
                <p>
                  I&apos;m Jenny-Lee, founder of Sweet Dreams Jellybean and mum to two cheeky little boys. I have
                  experienced just how difficult things can get when your child doesn&apos;t sleep well. I have been severely sleep-deprived, I have
                  been utterly exhausted and I have felt truly helpless, not knowing what to do to make things better for my family. It&apos;s a
                  really tough place to be, but I am here to help you.
                </p>
                <p>
                  I have a first class science degree and have become really interested in the science behind sleep since having children. In the vast
                  majority of cases there will be a very good reason your child is experiencing sleep difficulties and so, instead of your sleep plan
                  simply telling you what to do, it will also explain why.
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h4">My boys and their sleep</Typography>}
              avatar={<MainLogoAvatar />}
            ></CardHeader>
            <CardContent className={classes.infoCardContent}>
              <Typography variant="body1">
                <p>
                  My first-born, Archie, was a dream sleeper up until around 4 months. For the next 14 months we really struggled as a family.
                  30-minute catnaps, feeding to sleep every 1-2 hours overnight and starting our days between 4:30am and 5:30am was really taking its
                  toll.
                </p>
                <p>To say we were exhausted is an understatement. We were severely sleep-deprived and just didn&apos;t know what to do.</p>
                <p>
                  When Archie eventually started napping well and sleeping through the night, we had so much more time to enjoy being a family. Life
                  became so much happier and we could actually function again!
                </p>
                <p>
                  When my second baby Reuben was born, I was determined to not get into the same situation. We introduced healthy sleep habits from
                  day one and Reuben began self-settling from a young age. He has always loved his sleep and, in fact, even before he could speak, he
                  started asking to go to bed when he was tired - something we could never have dreamed of with Archie!
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.gridItem} item xs={12} md={9}>
          <Card raised>
            <CardHeader
              className={classes.infoCardHeader}
              title={<Typography variant="h4">Working With Me</Typography>}
              avatar={<MainLogoAvatar />}
            ></CardHeader>
            <CardContent className={classes.infoCardContent}>
              <Typography variant="body1">
                <p>I absolutely love getting to know the families I work with and pride myself on providing a very personalised service.</p>
                <p>
                  My sleep plans rely on understanding what your child&apos;s personality is like, what your parenting styles and beliefs are and what
                  you actually want to achieve during our time together. This is different for each child and for each family and your plan will be
                  tailored to your individual situation.
                </p>
                <p>
                  We will discuss the wide range of factors that may be affecting your child&apos;s sleep and also the sleep strategies that could
                  work for your family. I will help and support you for two weeks to ensure that your plan is working and to make any adjustments
                  along the way.
                </p>
                <p>
                  I truly believe that sleep brings more happiness and enjoyment to a family. You don&apos;t have to live with cranky days, sleepless
                  nights and the draining effects that sleep deprivation can bring. If you are currently struggling with your child&apos;s sleep,
                  please contact me and we can have a free chat about how I can help.
                </p>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutDetails;
