import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react';
import materialTheme from '../../theme/material-theme';
import AboutDetails from './AboutDetails';
import { AboutWrapper } from './style';
import { BannerWrapper } from '../../components/common-styles';
import BannerImage from '../../images/about.jpg';

interface AboutProps {}

const BannerComponent = BannerWrapper(BannerImage);

const About: React.FunctionComponent<AboutProps> = () => {
  return (
    <ThemeProvider theme={materialTheme}>
      <BannerComponent />
      <AboutWrapper>
        <AboutDetails />
      </AboutWrapper>
    </ThemeProvider>
  );
};

export default About;
