import styled from 'styled-components';

export const AboutWrapper = styled.div`
  position: relative;
  padding: 32px 75px 0 75px;
  @media (max-width: 990px) {
    padding: 30px 45px 30px 45px;
  }
  @media (max-width: 575px) {
    padding: 30px 25px 0 25px;
  }
`;
